export function useStatusToast() {
    const state = useState<StatusToast>('status-toast-state', () => ({
        text: '',
        loading: false,
        visible: false,
        progress: null,
        icon: null,
    }))
    const lastTimeout = useState('status-toast-last-timeout', () => 0)

    function update(options: {
        text: string
        loading?: boolean
        progress?: number
        timeout?: number | null
        icon?: string
    }) {
        if (state.value.visible)
            window.clearTimeout(lastTimeout.value)

        state.value.text = options.text
        state.value.loading = options.loading ?? false
        state.value.progress = options.progress ?? null
        state.value.icon = options.icon ?? null

        if ((typeof options.timeout === 'number' && options.timeout > 0) || options.timeout === undefined) {
            lastTimeout.value = window.setTimeout(clear, options.timeout ?? 2000)
        }

        state.value.visible = true
    }

    function clear() {
        state.value.visible = false
        state.value.progress = null
        state.value.icon = null
    }

    return {
        update,
        clear,
        text: computed(() => state.value.text),
        loading: computed(() => state.value.loading),
        visible: computed(() => state.value.visible),
        progress: computed(() => state.value.progress),
        icon: computed(() => state.value.icon),
    }
}

type StatusToast = {
    text: string
    loading: boolean
    visible: boolean
    icon: string | null
    progress: number | null
}
