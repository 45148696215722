import revive_payload_client_xKkFabesoB from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.7.4_encoding@0.1.13_eslint@9.4.0_ioredis@5.4_lrdpovcdehdw6fs4a6akaouz4m/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_QrkGg6p4ZU from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.7.4_encoding@0.1.13_eslint@9.4.0_ioredis@5.4_lrdpovcdehdw6fs4a6akaouz4m/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_cqh4jYl2yJ from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.7.4_encoding@0.1.13_eslint@9.4.0_ioredis@5.4_lrdpovcdehdw6fs4a6akaouz4m/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import clerk_BDmPDtQAH6 from "/vercel/path0/node_modules/.pnpm/vue-clerk@0.6.9_@clerk+backend@1.13.9_react@18.3.1__react@18.3.1_vue@3.5.1_typescript@5.4.5_/node_modules/vue-clerk/dist/nuxt/runtime/plugins/clerk.js";
import payload_client_6yyZILhPqu from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.7.4_encoding@0.1.13_eslint@9.4.0_ioredis@5.4_lrdpovcdehdw6fs4a6akaouz4m/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_zc1lgqKYjX from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.7.4_encoding@0.1.13_eslint@9.4.0_ioredis@5.4_lrdpovcdehdw6fs4a6akaouz4m/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_wVmcy9Ee8e from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.7.4_encoding@0.1.13_eslint@9.4.0_ioredis@5.4_lrdpovcdehdw6fs4a6akaouz4m/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_l5yzAb9iQg from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.7.4_encoding@0.1.13_eslint@9.4.0_ioredis@5.4_lrdpovcdehdw6fs4a6akaouz4m/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/packages/application/.nuxt/components.plugin.mjs";
import prefetch_client_yWqxpQvDOS from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.7.4_encoding@0.1.13_eslint@9.4.0_ioredis@5.4_lrdpovcdehdw6fs4a6akaouz4m/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_tLoIUb89xH from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.18.0_axios@1.7.4_change-case@4.1.2_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+_x22kux6e45dl4tjuarud6nnjo4/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_6n5ieW4zr9 from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.18.0_axios@1.7.4_change-case@4.1.2_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+_x22kux6e45dl4tjuarud6nnjo4/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_nJsQFUFcrZ from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.18.0_axios@1.7.4_change-case@4.1.2_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+_x22kux6e45dl4tjuarud6nnjo4/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_G8sumvWDCx from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_nDMyJGHB1k from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@1.5.5_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.4_terser@5.34.1__vu_6fmgerhthgy46p5ciczu7qy56i/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import unocss_MzCDxu9LMj from "/vercel/path0/packages/application/.nuxt/unocss.mjs";
import sentry_client_BvsRHKxX1a from "/vercel/path0/node_modules/.pnpm/@sentry+nuxt@8.26.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+api@1._uvuu6bfdqrtd2gjzly5ttdpyrm/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/vercel/path0/packages/application/.nuxt/sentry-client-config.mjs";
import plugin_client_7SvU6thrhf from "/vercel/path0/node_modules/.pnpm/nuxt-gtag@3.0.1_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_prod_client_kCdZdAQKIk from "/vercel/path0/node_modules/.pnpm/nuxt-monaco-editor@1.2.9_magicast@0.3.5_monaco-editor@0.49.0_rollup@4.24.0_vite@5.4.8_@types+_hsw2yfrlsbr5bulvw6tqzkxh2e/node_modules/nuxt-monaco-editor/dist/runtime/plugin-prod.client.mjs";
import plugin_client_LQYlsYh4Jb from "/vercel/path0/node_modules/.pnpm/@samk-dev+nuxt-vcalendar@1.0.3_magicast@0.3.5_rollup@4.24.0_vue@3.5.1_typescript@5.4.5__webpack-sources@3.2.3/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.mjs";
import plugin_ziYcKwnJdq from "/vercel/path0/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import _0_force_logout_per_revision_TeCF2tpkEo from "/vercel/path0/packages/application/plugins/0.force-logout-per-revision.ts";
import feature_flags_3X7A4FWWIa from "/vercel/path0/packages/application/plugins/feature-flags.ts";
import input_autowidth_bJoGoxmQ7Q from "/vercel/path0/packages/application/plugins/input-autowidth.ts";
import liveblocks_ch8igrfBKq from "/vercel/path0/packages/application/plugins/liveblocks.ts";
import test_id_TLE30LqioL from "/vercel/path0/packages/application/plugins/test-id.ts";
export default [
  revive_payload_client_xKkFabesoB,
  unhead_QrkGg6p4ZU,
  router_cqh4jYl2yJ,
  clerk_BDmPDtQAH6,
  payload_client_6yyZILhPqu,
  navigation_repaint_client_zc1lgqKYjX,
  check_outdated_build_client_wVmcy9Ee8e,
  chunk_reload_client_l5yzAb9iQg,
  components_plugin_KR1HBZs4kY,
  prefetch_client_yWqxpQvDOS,
  slideovers_tLoIUb89xH,
  modals_6n5ieW4zr9,
  colors_nJsQFUFcrZ,
  plugin_client_G8sumvWDCx,
  plugin_nDMyJGHB1k,
  unocss_MzCDxu9LMj,
  sentry_client_BvsRHKxX1a,
  sentry_client_config_o34nk2sJbg,
  plugin_client_7SvU6thrhf,
  plugin_prod_client_kCdZdAQKIk,
  plugin_client_LQYlsYh4Jb,
  plugin_ziYcKwnJdq,
  _0_force_logout_per_revision_TeCF2tpkEo,
  feature_flags_3X7A4FWWIa,
  input_autowidth_bJoGoxmQ7Q,
  liveblocks_ch8igrfBKq,
  test_id_TLE30LqioL
]